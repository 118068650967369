/** @format */

import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import GlobalLayout from "../components/layouts/GlobalLayout";
import SiteHeader from "../components/SiteHeader/SiteHeader";
import Footer from "../components/Footer/Footer";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

import {
    StyledOdNas,
    StyledLeftWrapperWithImage,
    StyledRightWrapper,
    StyledGreenSection,
    StyledLeftGreenWrapper,
    StyledRightGreenWrapper,
    StyledInneSection,
    StyledLeftImage,
    StyledRightImage,
    StyledCenterWrapper,
} from "../components/Odnas/StyledOdNas";

const Odnas = () => {
    const data = useStaticQuery(graphql`
        query OdNasDlaWas {
            datoCmsOdNasDlaWa {
                naglowek
                pierwszeZdjecie {
                    gatsbyImageData(placeholder: TRACED_SVG)
                }
                prawoTekstNaglowek
                prawoLista {
                    element
                }
                lewoTekstNaglowek
                lewoLista {
                    element
                }
                prawoZdjecie {
                    gatsbyImageData(placeholder: TRACED_SVG)
                }
                iInneNaglowek
                iInneLista {
                    element
                }
            }
        }
    `);
    const image1 = getImage(data.datoCmsOdNasDlaWa.pierwszeZdjecie);
    const image2 = getImage(data.datoCmsOdNasDlaWa.prawoZdjecie);

    return (
        <GlobalLayout>
            <HelmetTemplate
                title="Od nas"
                desc="Co oferujemy w naszym hotelu"
            />
            <SiteHeader title="Od nas" />
            <StyledOdNas>
                <h2>{data.datoCmsOdNasDlaWa.naglowek}</h2>
                <StyledCenterWrapper>
                    <StyledLeftWrapperWithImage>
                        <StyledLeftImage
                            image={image1}
                            alt="Przystanek na Leśnej"
                        />
                    </StyledLeftWrapperWithImage>
                    <StyledRightWrapper>
                        <h3>{data.datoCmsOdNasDlaWa.prawoTekstNaglowek}</h3>
                        <ul>
                            {data.datoCmsOdNasDlaWa.prawoLista.map(
                                ({ element }) => (
                                    <li>{element}</li>
                                )
                            )}
                        </ul>
                    </StyledRightWrapper>
                </StyledCenterWrapper>
                <StyledGreenSection>
                    <div>
                        <StyledLeftGreenWrapper>
                            <h3>{data.datoCmsOdNasDlaWa.lewoTekstNaglowek}</h3>
                            <ul>
                                {data.datoCmsOdNasDlaWa.lewoLista.map(
                                    ({ element }) => (
                                        <li>{element}</li>
                                    )
                                )}
                            </ul>
                        </StyledLeftGreenWrapper>
                        <StyledRightGreenWrapper>
                            <StyledRightImage
                                image={image2}
                                alt="Przystanek na Leśnej"
                            />
                            <Link to="/galeria">
                                Zobacz więcej zdjęć ogrodu
                            </Link>
                        </StyledRightGreenWrapper>
                    </div>
                </StyledGreenSection>
                <StyledInneSection>
                    <h3>{data.datoCmsOdNasDlaWa.iInneNaglowek}</h3>
                    <ul>
                        {data.datoCmsOdNasDlaWa.iInneLista.map(
                            ({ element }) => (
                                <li>{element}</li>
                            )
                        )}
                    </ul>
                </StyledInneSection>
            </StyledOdNas>
            <Footer />
        </GlobalLayout>
    );
};

export default Odnas;
