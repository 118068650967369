/** @format */

import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledCenterWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
`;
export const StyledOdNas = styled.section`
    > h2 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 160.7%;
        padding: 0 20px 20px 40px;
        max-width: 1400px;
        margin: 70px auto 20px auto;
    }
    @media only screen and (max-width: 767px) {
        > h2 {
            padding: 0 20px 20px 40px;
            font-size: 24px;
        }
    }
    @media only screen and (max-width: 500px) {
        > h2 {
            padding-left: 20px;
            margin-top: 40px;
        }
    }
`;
export const StyledLeftWrapperWithImage = styled.div`
    padding-left: 20px;
    width: 40%;
    @media only screen and (max-width: 767px) {
        display: none;
    }
`;
export const StyledRightWrapper = styled.div`
    width: 60%;
    padding-left: 30px;
    > h3 {
        font-family: "Roboto";
        font-style: 500;
        font-weight: 400;
        font-size: 26px;
        line-height: 160.7%;
        padding-left: 28px;
    }
    > ul {
        margin: 20px 0 20px 52px;
        > li {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 26px;
            line-height: 160.7%;
            padding: 5px 0;
        }
    }
    @media only screen and (max-width: 1172px) {
        > h3 {
            font-size: 20px;
        }
        > ul {
            > li {
                font-size: 18px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 20px;
        > h3 {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 500px) {
        padding-left: 0;
    }
`;
export const StyledGreenSection = styled.div`
    margin-top: 65px;
    background: #11ca59;
    width: 100%;
    > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1400px;
        margin: 0 auto;
    }
`;
export const StyledLeftGreenWrapper = styled.div`
    padding-left: 40px;
    > h3 {
        font-family: "Roboto";
        font-style: 500;
        font-weight: 400;
        font-size: 26px;
        line-height: 160.7%;
        color: #fff;
    }
    > ul {
        margin: 20px 0 20px 22px;
        > li {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 26px;
            line-height: 160.7%;
            padding: 5px 0;
            color: #fff;
        }
    }
    @media only screen and (max-width: 1172px) {
        > h3 {
            font-size: 20px;
        }
        > ul {
            > li {
                font-size: 18px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 30px 20px 20px 40px;
        > h3 {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 500px) {
        padding: 30px 0 20px 20px;
    }
`;
export const StyledRightGreenWrapper = styled.div`
    padding: 40px 40px 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > a {
        color: #000;
        text-decoration: none;
        display: block;
        padding: 20px 30px 20px 30px;
        background-color: #fff;
        margin-top: 42px;
        border-radius: 30px;
        font-family: "Roboto";
        cursor: pointer;
        transition: color 250ms, background-color 250ms;
        &:hover{
            background-color: #D95720;
            color: #fff;
        }
    }
    @media only screen and (max-width: 767px) {
        display: none;
    }
`;
export const StyledInneSection = styled.section`
    padding: 40px 60px;
    max-width: 1400px;
    margin: 0 auto;
    > h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 160.7%;
        padding: 40px 0;
        display: block;
        text-align: left;
        margin-left: -18px;
    }
    > ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 5px;
        > li {
            width: 49%;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            line-height: 160.7%;
            padding-right: 20px;
        }
    }

    @media only screen and (max-width: 1172px) {
        > h3 {
            font-size: 20px;
        }
        > ul {
            > li {
                font-size: 18px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        > ul {
            > li {
                width: 100%;
            }
        }
        > h3 {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 500px) {
        padding: 30px 40px 20px 40px;
    }
`;
export const StyledRightImage = styled(GatsbyImage)`
    position: relative !important;
    width: 100%;
    max-width: 400px;
    height: 100%;
    box-shadow: 13px 8px 17px 6px rgba(0, 0, 0, 0.42);
    border-radius: 22px;

    @media only screen and (max-width: 1172px) {
        max-width: 320px;
    }

    @media only screen and (max-width: 767px) {
        display: none;
    }
`;
export const StyledLeftImage = styled(GatsbyImage)`
    position: relative !important;
    width: 100%;
    max-width: 400px;
    height: 100%;
    box-shadow: 13px 8px 17px 6px rgba(0, 0, 0, 0.42);
    border-radius: 22px;

    @media only screen and (max-width: 1172px) {
        max-width: 320px;
    }

    @media only screen and (max-width: 767px) {
        display: none;
    }
`;
